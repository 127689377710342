.bookmark-list {
  display: flex;
  justify-content: flex-end;
  margin-left: -10px; /* gutter size offset */
  width: auto;
}
.bookmark-list__col {
  padding-left: 10px; /* gutter size */
  background-clip: padding-box;

  & > div {
    margin-bottom: 10px; /* space between items */
  }
}